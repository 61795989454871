import { createContext, useRef, useContext, useMemo } from 'react';
import axios from 'axios';

import { useAuth } from './useAuth';

const AxiosContext = createContext(null);

export const AxiosInstanceProvider = ({ children }) => {
  const instanceRef = useRef(axios.create({
    // new change 1 nov 2023
    baseURL: '/api/v1',
    // old 
    //baseURL: '/api',
  }));
  // const { doLogin } = useAuth();

  instanceRef.current.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      // TODO Log and/or show error?
      if (error.response.status === 401) {
        // TODO Get a new token?
        // TODO Try doing the request one more time?
        // TODO Redirect to login?
        // doLogin(window.location.pathname);

      } 
      if (error.response.status > 400) {
        console.error("USEAPI", error.response);
      }
      return Promise.reject(error);
    }
  );

  return (
    <AxiosContext.Provider value={instanceRef.current}>
      {children}
    </AxiosContext.Provider>
  );
};

export const useAPI = () => {
  const contextInstance = useContext(AxiosContext);
  const instance = useMemo(() => { return contextInstance || axios; }, [contextInstance]);
  const { token } = useAuth();

  const request = async (method, resource, data) => {
      //console.log("requestAPI",resource);
      return instance({
        json: true,
        method,
        url: resource,
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((req) => req.data);
  };

  const callAPI = async (payLoad) => {
    const method = (payLoad.method) ? payLoad.method : 'get';
    const dataContent = (payLoad.data) ? payLoad.data : '';
    return request(method, payLoad.resource, dataContent);
  };

  const version = async () => {
    return request('get', '/version.json');
  };

  const deviceModelAPI = async (payLoad) => {
    //return request('get', `/deviceModel/${deviceModelId}`);
    const method = (payLoad.method) ? payLoad.method:'get';
    const deviceModelId = (payLoad.deviceModelId) ? '/'+payLoad.deviceModelId:'';
    const limit = (payLoad.limit) ? 'limit='+payLoad.limit:'';
    const offset = (payLoad.offset) ? '&offset='+payLoad.offset:'';
    const docId = (payLoad.docId) ? '&docId='+payLoad.docId:'';
    const dataContent = (payLoad.data) ? payLoad.data:'';
    //console.log("dataDeviceModel API", deviceModelId, docId);
    return request(method, `/deviceModel${deviceModelId}?${limit}${offset}${docId}`, dataContent);
  };

  const adminTenant = async () => {
    return request('get', '/admin/tenant');
  };

  //
  /////////////////////////////////////////
  const userInfo = async () => {
    return request('get', '/userinfo');
  };

  const assets = async (payLoad) => {
    const method = (payLoad.method) ? payLoad.method:'get';
    const dataContent = (payLoad.data) ? payLoad.data:'';
    return request(method, '/tenant/'+payLoad.resource, dataContent);
  };


  const addTenantAsset = async ({ id, data }) => {
    return request('post', `/tenant/${id}/asset`, data);
  };

  // TODO Add more backend requests here.

  return { callAPI, userInfo, version, deviceModelAPI, adminTenant, addTenantAsset };
};
