import React, {useEffect, useState } from "react";


export function CheckOnlineStatus(token, doLogout) {
  var status = false;
  var tokenInfo = '';

  const TodayDate = new Date(Date.now());
  const TimeNow = Epoch(TodayDate); 
 
  // checktoken 
  if(token) {
      tokenInfo = token; //jwt_decode(token);
      const TimeExpire = tokenInfo.exp+'000'; 

      if(TimeNow > TimeExpire) {
        // expire 
        status = false;
        doLogout();
        window.location.replace('/');
      } else {
        // still access
        status = true;
      }
  }

  return {
          tokenInfo: tokenInfo,
          status: status
        }

}


// decimals without round
export function ToFixedTrunc(x, n) {
  const v = (typeof x === 'string' ? x : x.toString()).split('.');
  if (n <= 0) return v[0];
  let f = v[1] || '';
  if (f.length > n) return `${v[0]}.${f.substr(0,n)}`;
  while (f.length < n) f += '0';
  return `${v[0]}.${f}`
}

export function DateNames() {
    // create date
    const days = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
    const month = [1,2,3,4,5,6,7,8,9,10,11,12];
    const monthName = ['','Jan','Feb','Mars','April','Maj','Juni','Juli','Aug','Sep','Okt','Nov','Dec'];
  
    return {
      days: days,
      month: month,
      monthName: monthName
    }
}

// settings to connect to AWS buckets
export const AWSConfig = () => {
  const domain = window.location.hostname;
  var FRONTEND_URL = '';
  //FRONTEND_URL=process.env.FRONTEND_URL;
  //FRONTEND_URL='http://sg-john-www.s3.eu-north-1.amazonaws.com';
  //FRONTEND_URL='http://unstable-installer.enerflex.cloud';
  if(domain === 'localhost') {
    FRONTEND_URL='http://sg-frontend-shared-guide-1c1auc6phguld.s3-website.eu-north-1.amazonaws.com';
  }
  const config = {
    GuideUrl:FRONTEND_URL+'/guide',
  }
  return config;
}

// check if role have access to view frontend
// CALL EXAMPLE: {Functions.RoleAccess('admin') && 
export function RoleAccess(accessType, access) {
    var resp = false;
    const tenantAccessStorage = (localStorage.getItem("tenantAccess") !== '') ? JSON.parse(localStorage.getItem("tenantAccess")):'';
    const tenantAccess = (access) ? access:tenantAccessStorage;
    const tenantId = localStorage.getItem("tenant");
    if(tenantAccess &&tenantAccess.length > 0) {
      tenantAccess.forEach((item, i) => {
        if((item.role === "superadmin") || (accessType === item.role && tenantId === item.tenantId)){ resp = true;}
      })
    }
    //console.log("tenantAccess",tenantAccess);
  return resp;
}

// auto offset to collect all data




// Check for type name
export function ModelType(props) {
  const type = props.type;
  var typeDesc = '';
  switch(type) {
    case "GSHP" : typeDesc = "Berg/jord/sjö"; break;
    case "ASHP" : typeDesc = "Luft/vatten"; break;
    case "EAHP" : typeDesc = "Frånluft"; break;
    default: typeDesc = "Ospecifierad typ"; break;
  }
  return typeDesc;
}

export function SelectTenant(props) { 
   const tenantAccessList = (localStorage.getItem("tenantAccessList")) ? JSON.parse(localStorage.getItem("tenantAccessList")):localStorage.getItem("tenantAccessList");
   const tenantAccess= (props.tenantList) ? props.tenantList:tenantAccessList;
 
   var selectTenantList = [];
   const firstSelect = (tenantAccess && tenantAccess.length > 0) ? tenantAccess[0].tenantId:'';
   const storageTenant =  (localStorage.getItem("tenant")) ? localStorage.getItem("tenant"):firstSelect; 
   const selectedTenant = props.tenantId ? props.tenantId:storageTenant;
   
   // use first tenant if not selected 
   if(!localStorage.getItem("tenant") && firstSelect !== ''){
    localStorage.setItem("tenant", firstSelect); 
   }

   //console.log("TenantfirstSelect",firstSelect);

   return(<>
            {(tenantAccess && tenantAccess.length === 0) || (tenantAccess && tenantAccess.length > 0) ? (

              <select onChange={(e)=>props.SelectTenant(e)} value={selectedTenant} name="tenant" className="btn btn-info  w-90">
                <option value="">- Välj portal - </option>
                  {tenantAccess.map((item, i)=> {


                      if(selectTenantList.indexOf(item.tenantId) < 0) {
                        const name = (item.name) ? item.name:'Namn saknas';
                        const viewname = (item && item.company) ? item.company:name;
                        selectTenantList.push(item.tenantId);
                        
                        // set name of selected
                        if(item.tenantId === selectedTenant) {
                          props.SaveTenantName(viewname);
                        }

                        return (
                          <option key={i} value={item.tenantId}>{viewname}</option>
                        )
                      } else {return null;}
                    })
                  }
              </select>
            ):(<>
              <span className="spinner-border"></span>
            </>)
          }
   </>);
}

/**
*
* @redirectpage
* @example  Functions.Redirect('/page');
*/
export function Redirect(url) {
  //console.log("Redirect ",url);
  window.location.replace(url);
}

/**
* @Get url string ?email=email@emailaddress.com
* @example: const email = Functions.QueryValue('email');
* @return email
*
*/
export function QueryValue(string){
  const urlParams = new URLSearchParams(window.location.search);
  const resp = urlParams.get(string);
  return resp;
}

export function CheckEnviroment() {
  let host = window.location.host;
  console.log("enviroment", host);

}


//***************************************
// DATE
//***************************************

// Return epoch time: 1654589551874
// Convert 2022-08-11T13:00:00Z to epoch time
export function dateTime(){
  var d = new Date().getTime();
  return d;
}

// epoch to view localtime
export function dateTimeUTC(){
  const dd =  new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString();
  //const dd = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString();
  //console.log("date",dd);
  return dd;
}

export function CurrentDate(){
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
      month = (month < 10 ) ? '0'+month:month;
  let year = date.getFullYear();
  // This arrangement can be altered based on how we want the date's format to appear.
  let currentDate = `${year}-${month}-${day}`;
  return currentDate;
}

//
// Date data for component
export const dateItem = {
  begin : new Date().setHours(0,0,0,0),
}

export function RandId(){
  var num = Math.floor(Math.random() * 100000);
  return num;
}

// Convert 2022-08-11T13:00:00Z to epoch time
export function Epoch(date) {
  const tt = Date.parse(date);
  return tt;
}



// convert API time UTC to local GMT
export function UTCtoLocalTime(date) {
  if(date) {
    //console.log("DATE UTCtoLocalTime",date);
    var newDate = new Date(date);
    //var offset = newDate.getTimezoneOffset('sv') / 60;
    //var hours = newDate.getHours()
    //newDate.setHours(hours);

    // return time as string
    const day = (newDate.getDate() < 10) ? '0'+newDate.getDate():newDate.getDate();
    const year = newDate.getFullYear();
    const getMonth = parseInt(newDate.getMonth() + 1); //newDate.getMonth();
    const month = (getMonth < 10) ? '0'+getMonth:getMonth;
    const HH = (newDate.getHours()) < 10 ? '0'+newDate.getHours():newDate.getHours();
    const MM = (newDate.getMinutes() < 10) ? '0'+newDate.getMinutes():newDate.getMinutes();      
    const result = `${year}-${month}-${day} ${HH}:${MM}:00`;      
    return result;       
  } else {
    return null;
  }
}

// convert time to UTC before saving to server
// guide - https://dev.to/zuhairnaqi/how-to-work-with-utc-time-properly-in-react-native-inf
export function TimeToUTC(date) {
  var createDate = new Date(date);        
  const newDate = new Date(createDate.toUTCString().slice(0, -4));

  // return time as string
  const day = (newDate.getDate() < 10) ? '0'+newDate.getDate():newDate.getDate();
  const year = newDate.getFullYear();
  const getMonth = parseInt(newDate.getMonth() + 1); 
  const month = (getMonth < 10) ? '0'+getMonth:getMonth;
  const HH = (newDate.getHours()) < 10 ? '0'+newDate.getHours():newDate.getHours();
  const MM = (newDate.getMinutes() < 10) ? '0'+newDate.getMinutes():newDate.getMinutes();      
  const result = `${year}-${month}-${day}T${HH}:${MM}:00.000Z`;    

  console.log("DATE convertLocalDateToUTCIgnoringTimezone", newDate.getMonth(), date, result, newDate);  
  return result;
}

// Create one list from metricsdata
// count actions SG-READY filter data 
export function MetricsCreateList(data) {
  const actions = [];
  if(data) {
    data.forEach((item, i) => {
      item.values.forEach((itemChild, i) => {
        const obj = {
          time:item.time,
          values:itemChild
          };
          actions.push(obj);
          //setMetricsActions(old => [...old, obj]);
      });
    });
  }
  return actions;
}

export function SortList(data, row, reverse) {
  var objects = data;
  //
  // sort output *****************
  if(row) {
    var sorter = (sortBy) => (a, b) => a[sortBy] > b[sortBy] ? 1 : -1;
      // reverse
      if(reverse) {
        sorter = (sortBy) => (a, b) => a[sortBy] > b[sortBy] ? -1 : 1;
      }

     objects.sort(sorter(row));
  }
  return objects;
}


// count stop actions set to state ***************
export function CountActions(data) {
  var priority0 = data.filter(x => x.values.last.priority === 0);
  var priority1 = data.filter(x => x.values.last.priority === 1);
  var priority2 = data.filter(x => x.values.last.priority === 2);
  var modeSG1 = data.filter(x => x.values.last.mode === 'SG-READY-1');
  var modeSG2 = data.filter(x => x.values.last.mode === 'SG-READY-2');
  var modeSG3 = data.filter(x => x.values.last.mode === 'SG-READY-3');
  var modeSG4 = data.filter(x => x.values.last.mode === 'SG-READY-4');
  var modeBYPASS = data.filter(x => x.values.last.mode === 'BYPASS');
  var simOutdoor127 = data.filter(x => x.values.last.simOutdoor === '127');
  var simOutdoorNot127 = data.filter(x => x.values.last.simOutdoor !== '127');

  // snabbstopp måste vara i mode: SG-1
  const totalActions = parseInt(priority1.length + priority2.length);
  const time = (data && data[0] && data[0].time) ? data[0].time:null;

  // calc
  var spotprice = 0;
  var other = 0;
  var closeByCustomer = 0;
  var normal = 0;
  data.forEach(item => {
     if ((item.values.last.mode !== "SG-READY-2") && (item.values.last.mode !== "BYPASS") || (item.values.last.simOutdoor !== 127)) {
      if (item.values.last.priority === 1 || item.values.last.priority === 3) {
        // Spotprisstyrning
        spotprice++;
      } else {
        // Övrig styrning
        other++;
      }
    }
    // Kolla om bypass
    if (item.values.last.mode === "BYPASS") {
      // Avstängd av kund
      closeByCustomer++;
    }
    // normal
    normal++;
  });
  
  const result =  {
    count: {
      spotprice: spotprice,
      other: other,
      closeByCustomer: closeByCustomer,
      normal: normal,

      priority0: priority0.length,
      priority1: priority1.length,
      priority2: priority2.length,
      modeSG1: modeSG1.length,
      modeSG2: modeSG2.length,
      modeSG3: modeSG3.length,
      modeSG4: modeSG4.length,
      modeBYPASS: modeBYPASS.length,
      simOutdoor127: simOutdoor127.length,
      simOutdoorNot127: simOutdoorNot127.length,
      totalActions:totalActions
    },
    data: {
      time: time,
      priority0: priority0,
      priority1: priority1,
      priority2: priority2,
      modeSG1: modeSG1,
      modeSG2: modeSG2,
      modeBYPASS: modeBYPASS
    }
  }

  return result;
}


 

//
// Render Json html values as html
export const renderHTML = (escapedHTML: string) => React.createElement("span", { dangerouslySetInnerHTML: { __html: escapedHTML } });

//
// Return youtube video link to embed
export function VideoView(name)
{
  // check video format
  var url = name;
  // youtube
  if (url !== undefined || url !== '') {
      // eslint-disable-next-line
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;

      var match = url.match(regExp);
      if (match && match[2].length === 11) {
          const embed = 'https://www.youtube.com/embed/' + match[2] + '?autoplay=0';
          const view = '<iframe class="" style="width:100%;height:25vh;" src="'+embed+'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
          return view;
      }
      else {
          // Do anything for not being valid
      }
  }
  return '';
}



function ifUrlExist(url) {
    let request = new XMLHttpRequest;
    request.open('GET', url, true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    request.setRequestHeader('Accept', '*/*');
    request.onprogress = function(event) {
        let status = event.target.status;
        let statusFirstNumber = (status).toString()[0];
        //console.log("ifUrlExist",statusFirstNumber);
        switch (statusFirstNumber) {
            case '2':
                request.abort();
                return true;
            default:
                request.abort();
                return false;
        };
    };
    request.send('');
};

/**
* @example
  const item = await API.APIpromise(Config.API.url+'/asset?id='+id,'DELETE','');
*/
export function APIpromise(APIUrl, method, data) {
  return new Promise(resolve => {
      const methodType = (method) ? method:'GET';
      var payLoad = '';
      if(data){
         payLoad = {
          method: methodType,
          headers: { 'Accept': 'application/json',
                     'Content-Type': 'application/json'
                   },
          mode: 'no-cors',
          body: JSON.stringify(data)
         }
      } else {
         payLoad = {
          method: methodType,
          headers: {
                     'Content-Type': 'application/json',
                   },
          mode: 'no-cors',
         }
      }

     // console.log("APIpromise",payLoad);

      fetch(APIUrl, payLoad)
       .then((res) => res.json())
       .then((response) => {
           //console.log('APIpromise RESP: ', JSON.stringify(payLoad), JSON.stringify(response));
           //return response;
           resolve(response);
       })
       .catch((error) => {
           console.error('APIpromise error', error);
           //return error;
           resolve('');
      });

  });
}

 

/**
******************************************
* @weather API
*
******************************************
*/
export function Weatherapi() {
  // doc -https://reactjs.org/docs/faq-ajax.html
  const [error, setError] = useState(null);
  //const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const methodType = 'GET'; //(method) ? method:'GET';

  // Auth
  const link = "https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=56.21676783351955&lon=12.548688927993668";

  useEffect(() => {
    console.log("Run API: "+link);
    fetch(link, {
                  method: methodType,
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/x-www-form-urlencoded'
                  }
                }
      ).then(res => res.json())
      .then(
        (result) => {
          console.log("API result: ", result);
          //setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          //setIsLoaded(true);
          setError(error);
        }
      )
  }, []);

  if (error) {
    console.log("Weatherapi error: "+error);
    return {error};
  } else {
    //console.log("Weatherapi resp: "+JSON.stringify(items));
    return items;
  }
}
