import { useState, useEffect, createContext, useContext, useReducer } from 'react';

const storagePrefix = 'easyserv';

// Session storage.

export const useSessionStorage = (key, defaultValue) => {
  const [state, setState] = useState(() => {
    const storedValue = sessionStorage.getItem(`${storagePrefix}-${key}`);
    return storedValue !== null && storedValue !== undefined
      ? JSON.parse(storedValue)
      : defaultValue;
  });

  useEffect(() => {
    sessionStorage.setItem(`${storagePrefix}-${key}`, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

// Local storage.

export const useLocalStorage = (key, defaultValue) => {
  const [state, setState] = useState(() => {
    const storedValue = window.localStorage.getItem(`${storagePrefix}-${key}`);
    return storedValue !== null && storedValue !== undefined
      ? JSON.parse(storedValue)
      : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(
      `${storagePrefix}-${key}`,
      JSON.stringify(state),
    );
  }, [key, state]);

  return [state, setState];
};

// Data store.
// In memory. Will persist when components are unmounted, but not on app reload.

const StoreContext = createContext();

const reducer = (state, action) => {
  return Object.assign({}, state, { [action.key]: action.value });
};

export const StoreProvider = ({ children, initialState }) => {
  const [storeState, storeDispatch] = useReducer(reducer, initialState || {});
  return (
    <StoreContext.Provider value={{ storeState, storeDispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

const useStore = () => useContext(StoreContext);

export const useDataStore = (key) => {
  const { storeState, storeDispatch } = useStore();

  const [state, setState] = useState(() => {
    return storeState[key];
  });

  useEffect(() => {
    setState(storeState[key]);
  }, [storeState[key]]);

  useEffect(() => {
    storeDispatch({key, value: state});
  }, [key, state]);

  return [state, setState];
};
