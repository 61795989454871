import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from "sg-frontend-shared/src/hooks/useAuth";

function LoginCallback() {
  const location = useLocation();
  const navigate = useNavigate();
  const { verifyLogin } = useAuth();

  const doneRef = useRef(false); // to only do verify (get token) once

  useEffect(() => {
    if (!doneRef.current) {
      doneRef.current = true;
      // v.1
      /*const redirect = verifyLogin(location.hash.replace('#', ''));
      if (redirect) {
        navigate(redirect, { replace: true });
      }
      */

      // v.2
      const redirect = verifyLogin(location.hash.replace('#', ''));
      if (redirect) {
        navigate(redirect, { replace: true });
      } else {
        navigate('/start');
      }

      console.log("B",doneRef.current, redirect);
    } else {

      console.log("A");
      //navigate('/start');
    }

  }, [location, navigate, verifyLogin]);

  return (null);
}

export default LoginCallback;
