import { useEffect, useRef } from "react";

import { useAuth } from "sg-frontend-shared/src/hooks/useAuth";

function Login() {
  const { doLogin } = useAuth();

  const doneRef = useRef(false); // to only do login once

  useEffect(() => {
    if (!doneRef.current) {
      doneRef.current = true;
      doLogin();
    }
  }, [doLogin]);

  return (null);
}

export default Login;
