import { useEffect, useRef } from "react";
import { useOutlet } from "react-router-dom";

//import Logo from 'sg-frontend-shared/src/components/Logo';
import { useAuth } from "sg-frontend-shared/src/hooks/useAuth";

function ProtectedLayout() {
  const { token, doLogin } = useAuth();
  const outlet = useOutlet();

  const loginDoneRef = useRef(false); // to only do login once

  useEffect(() => {
    if (!token && !loginDoneRef.current) {
      loginDoneRef.current = true;
      doLogin(window.location.pathname + window.location.search + window.location.hash);
    }
    
  }, [token, doLogin]);

  if (!token) {
      window.location.replace('/');
    //return (null);
  }
  /*
    return (
      <div>
        <ul>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li>
            <Link to="/debug">Debug</Link>
          </li>
          <li>
            <Link to="/install-demo">Install Demo</Link>
          </li>
          <li>
            <Link to="/install-demo-mutation">Install Demo Mutation</Link>
          </li>
          <li>
            <Link to="/install-demo-mutation-2">Install Demo Mutation 2</Link>
          </li>
        </ul>

        <hr />

        { outlet }
      </div>
    );
  */
  return (
    <div className="container">

      { outlet }

    </div>
  );
};

export default ProtectedLayout;
