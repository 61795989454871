import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "react-query";
import { StoreProvider } from "sg-frontend-shared/src/hooks/useStorage";
import { AuthProvider } from "sg-frontend-shared/src/hooks/useAuth";
import { AxiosInstanceProvider } from 'sg-frontend-shared/src/hooks/useAssetAPI';

import { userPoolAuthURL, userPoolClientId } from './config';

import './App.css';

import HomeLayout from './routes/HomeLayout';
import ProtectedLayout from './routes/ProtectedLayout';

import Home from './routes/Home';
import Login from './routes/Login';
import Logout from './routes/Logout';
import LoginCallback from './routes/LoginCallback';
/*
import Debug from './routes/Debug';
import InstallDemo from './routes/InstallDemo';
import InstallDemoMutation from './routes/InstallDemoMutation';
import InstallDemoMutation2 from './routes/InstallDemoMutation2';
*/
import Install from './routes/Install';
//import Assets from './routes/Assets';
import Start from './routes/Start';
import GuideDoc from './routes/GuideDoc';

const queryClient = new QueryClient();


function App() {
  return (
    <div>
      <Router>
        <AuthProvider userPoolAuthURL={userPoolAuthURL} userPoolClientId={userPoolClientId}>
          <StoreProvider initialState={{ counter: 0 }}>
            <AxiosInstanceProvider>
              <QueryClientProvider client={queryClient}>
                <Routes>
                  <Route element={<HomeLayout />}>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/oauth" element={<Login />} />
                    <Route path="/oauth/callback" element={<LoginCallback />} />
                    <Route path="/doc" element={<GuideDoc />} />
                  </Route>
                  <Route element={<ProtectedLayout />}>
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/install" element={<Install />} />
                    <Route path="/start" element={<Start />} />
                  </Route>
                </Routes>
              </QueryClientProvider>
            </AxiosInstanceProvider>
          </StoreProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

/**
* OLD
function App() {
  return (
    <div>
      <Router>
        <AuthProvider userPoolAuthURL={userPoolAuthURL} userPoolClientId={userPoolClientId}>
          <StoreProvider initialState={{ counter: 0 }}>
            <AxiosInstanceProvider>
              <QueryClientProvider client={queryClient}>
                <Routes>
                  <Route element={<HomeLayout />}>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/oauth" element={<Login />} />
                    <Route path="/oauth/callback" element={<LoginCallback />} />
                    <Route path="/doc" element={<GuideDoc />} />
                  </Route>
                  <Route element={<ProtectedLayout />}>
                    <Route path="/debug" element={<Debug />} />
                    <Route path="/install-demo" element={<InstallDemo />} />
                    <Route path="/install-demo-mutation" element={<InstallDemoMutation />} />
                    <Route path="/install-demo-mutation-2" element={<InstallDemoMutation2 />} />

                    <Route path="/install" element={<Install />} />
                    <Route path="/start" element={<Start />} />
                  </Route>
                </Routes>
              </QueryClientProvider>
            </AxiosInstanceProvider>
          </StoreProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}*/

export default App;
