import React from "react";

// COMPONENT
import * as Functions from 'sg-frontend-shared/src/components/Functions';

class Doc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
                  docId:'',
                  viewStep: 0,
                  stepDatadem:'',
                  stepDatacable:'',
                  stepDatainst:'',
                  stepDatadrift:'',
                  stepDataend:'',
                  stepDatafirst:'',
                };

    this.StepLoad = this.StepLoad.bind(this);
    this.StepList = this.StepList.bind(this);
    this.StepStateUpdate = this.StepStateUpdate.bind(this);
    this.StepState = this.StepState.bind(this);

    this.ViewImg = this.ViewImg.bind(this);

  };

  // this will run once when component A runs
  componentWillMount() {

    // Set docId from query
    const id = Functions.QueryValue('id');
    if(id) {
      this.setState({docId:id});
    }

    setTimeout(()=>{
      this.StepLoad('dem');
      this.StepLoad('cable');
      this.StepLoad('inst');
      this.StepLoad('end');
      this.StepLoad('drift');
      this.StepLoad('first');
    },350);
  }

  componentDidUpdate(){
    // back button and route
    window.onpopstate = e => {
      //this.ViewBack();
      console.log("BACK onpopstate", e);
    }

  }

  // settings to connect to AWS buckets
  AWSConfig(){
    var FRONTEND_URL = '';
    //FRONTEND_URL=process.env.FRONTEND_URL;
    //FRONTEND_URL='http://sg-john-www.s3.eu-north-1.amazonaws.com';
    //FRONTEND_URL='http://unstable-installer.enerflex.cloud';
    //FRONTEND_URL='http://sg-frontend-shared-guide-1c1auc6phguld.s3-website.eu-north-1.amazonaws.com';
    const config = {
      GuideUrl:FRONTEND_URL+'/guide',
    }
    return config;
  }


  View(step){
   this.setState({viewStep:step});
   console.log("ViewNext",step);
  }

  async StepLoad(section) {
    const randId = Functions.RandId();
    const fileName = 'data_'+section+'.json';
    const UrlFile  = this.AWSConfig().GuideUrl+'/'+this.state.docId+'/'+fileName+'?'+randId;
    const apidata = await Functions.APIpromise(UrlFile, 'GET');
    // Sort by pos
    if(apidata){apidata.sort((a, b) => a.pos - b.pos);}
    this.StepStateUpdate(section, apidata);
  }

  StepStateUpdate(section,data){
    if(section === "dem"){this.setState({"stepDatadem":data});}
    if(section === "cable"){this.setState({"stepDatacable":data});}
    if(section === "inst"){this.setState({"stepDatainst":data});}
    if(section === "drift"){this.setState({"stepDatadrift":data});}
    if(section === "end"){this.setState({"stepDataend":data});}
    if(section === "first"){this.setState({"stepDatafirst":data});}
  }

  StepState(section) {
    var resp = '';
    switch(section) {
      case "dem" : resp = this.state.stepDatadem; break;
      case "cable" : resp = this.state.stepDatacable; break;
      case "inst" : resp = this.state.stepDatainst; break;
      case "drift" : resp = this.state.stepDatadrift; break;
      case "end" : resp = this.state.stepDataend; break;
      case "first" : resp = this.state.stepDatafirst; break;
      default: resp = ""; break;
    }
    return resp;
  }

  // Check for type name
  ModelType(type){
    var typeDesc = '';
    switch(type) {
      case "GSHP" : typeDesc = "Berg/jord/sjö"; break;
      case "ASHP" : typeDesc = "Luft/vatten"; break;
      case "EAHP" : typeDesc = "Frånluft"; break;
      default: typeDesc = "Ospecifierad typ"; break;
    }
    return typeDesc;
  }

  // Check if list have img
  ViewImg(id, img){
    const imgUrl = this.AWSConfig().GuideUrl+'/'+id+'/'+img;
    var resp = '';
    if(img){
      resp = '<img src='+imgUrl+' width="100%" className="mt-2 mx-auto w-50 w-max-100">';
    }
    return Functions.renderHTML(resp);
  }

  ViewStep(s){
    const step = (s) ? s:"0";
    return(
      <div className="config-step">
        <ul className="multi-steps">
         <li className={Number(step)===0 ? ("is-active"):("")}></li>
         <li className={Number(step)===1 ? ("is-active"):("")}></li>
         <li className={Number(step)===2 ? ("is-active"):("")}></li>
         <li className={Number(step)===3 ? ("is-active"):("")}></li>
        {/*
         <li className={step==4 ? ("is-active"):("")}></li>
         <li className={step==5 ? ("is-active"):("")}></li>
         <li className={step==6 ? ("is-active"):("")}></li>
         */}

       </ul>
      </div>
    );
  }



  StepList(section, title, step){
    //const listType = Functions.QueryValue('type');
    const viewStep = this.state.viewStep;
    const viewBefore = step - 1;
    const viewNext = step + 1;

    const itemClass = (section === 'first') ? 'relative p-3 mb-5':'relative p-3 mb-5 border';
    const itemTxt = (section === 'first') ? 'Börja installera':'Nästa steg';
    const apidata = this.StepState(section);
    //apidata.sort((a, b) => a.pos - b.pos);

    const stepPos = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p'];
    return(
      <div className="mt-3 animate-left" id={"step-"+step}>
        {Number(viewStep) === step &&
          <div className="">

            <div className="text-center bold mb-4"><h2>{title}</h2></div>

            { apidata.length > 0 &&
              <>
                {
                  apidata.map((item, i) => (
                    <div className={itemClass} key={i}>
                        <div className="bold d-flex">
                        {section === 'first' ?
                          (
                            <div className="text-center"><h3>{item.title}</h3></div>
                          ):(
                            <div className="text-m">
                              <div className="dot-bg">{stepPos[i]}</div>
                              <div>{item.title}</div>
                            </div>
                          )
                        }
                      </div>
                      <div className="">{Functions.renderHTML(item.desc)}</div>
                      <div className="">{this.ViewImg(this.state.docId, item.img)}</div>
                    </div>
                  ))
                }
              </>
            }

            <div className="d-flex mt-3 mb-4">
              {viewStep > 0 &&
                <a href="#top" onClick={()=>this.View(viewBefore) }  className="btn btn-border w-40 no-wrap"><i className="fa fa-angle-left"></i>&nbsp;&nbsp; Föregående</a>
              }
              {Number(viewNext) === 4 ? (<></>):(
                <>
                  <a href="#top" onClick={()=>this.View(viewNext) } className="btn btn-border w-40  no-wrap ml-auto">{itemTxt} &nbsp;&nbsp;<i className="fa fa-angle-right"></i></a>
                </>
              )}
            </div>
          </div>
        }
      </div>
    );
  }

  render(){

    return(
      <div className="p-2 w-50 auto-100 mx-auto mb-5">
        {this.StepList('first', 'Inledning', 0)}
        {this.StepList('dem', '1. Demontering för installation', 1)}
        {this.StepList('cable', '2. Installation hårdvara', 2)}
        {this.StepList('inst', '3. Återställning efter installation', 3)}
        {/*this.StepList('drift', 'Driftsättning', 4)*/}
        {this.ViewStep(this.state.viewStep)}
          <br/><br/><br/><br/><br/>
      </div>
    );
  }

}
export default Doc;
