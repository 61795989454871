import * as Functions from './Functions';

// This use for step by step guide to install

export function Hardware1(callback){
  return(
    <div>
      <div className="content-box">
        <div className="content-title">1. Kontrollera att följande saker finns tillgängliga</div>
        <div className="ml-1">       
            <i className="fa fa-check"></i> SG-A, enhet som ansluts till värmepump <br/>
            <i className="fa fa-check"></i> SG-T, tempsensor som placeras mitt i huset <br/>
            <i className="fa fa-check"></i> Spänningsadapter till SG-A <br/>
            <i className="fa fa-check"></i> Kablage som kopplas mellan SG-A - värmepump <br/> 
            <div className='text-center mt-2'>
              <img src="/img/SG-complete.png" width="90%"></img>
            </div>
        </div>
      </div>

      <div className="content-title mt-3 content-box">
        2. Kontrollera att ett 230V vägguttag finns i närheten, 
        annars dras förlängningskabel från närmaste vägguttag
      </div>

      <div className="content-title mt-3 content-box">
        3. Ställ switch på SG-A i läge ”On" (tryck switch mot närmsta hörnet) 
        <div className='text-center mt-2 ml-1'>
            <img src="/img/SG-onoff.png" width="90%"></img>
        </div>
      </div>

      <div className="content-title mt-3 content-box">
        4. Sätt in medföljande 3st batterier i tempsensorn SG-T
        genom att skruva loss bakdelen och sätt in 3st batterier.
        <div className='text-center mt-2 ml-1'>
            <img src="/img/SG-T-batt-1.png" width="90%"></img>
        </div>
        <div className='text-center mt-2 ml-1'>
            <img src="/img/SG-T-batt-2.png" width="90%"></img>
        </div>
      </div>

      <div className="mt-3 content-info hidden">
        <i className="fa fa-info-circle text-2 mr-1"></i>     
      </div>

    </div>);
}


export function Hardware2(callback){
  return(
    <div>

      <div className="content-box">
        <div className="content-title">
          1. Placera enheten där den kommer befinna sig när den är färdiginstallerad. 
          Koppla ström-adaptern till SG-A och vänta tills lysdioden 
          lyser eller blinkar med GRÖNT sken, tar 10-30 sek.
        </div>
      </div>

      <div className="content-box  mt-3">
        <div className="content-title">    
          2. Kontrollera 4G-täckning tryck på 4G-knappen 
           i hålet vid blå pil (tryck t.ex. med ett gem):
        </div>
        <div>
          <span className="color-green text-big bold">&#9473;</span> Grönt: <strong>Bra täckning</strong>, gå vidare till nästa steg<br/>
          <span className="color-orange text-big bold">&#9473;</span> Orange: <strong>Bör fungera</strong>, gå vidare till nästa steg<br/>
          <span className="color-red text-big bold">&#9473;</span> Röd: <strong>Ej tillräckligt bra täckning</strong> för stabil funktion<br/>
        </div>
        <img src="/img/sa-hole.jpg" className="mt-2" alt="sa-hole" title="sa-hole" width="100%" />
      </div>

      <div className="mt-3 p-2 content-info text-grey ">
        <i className="fa fa-info-circle text-2"></i>&nbsp;
        <i>
        Vid röd LED, prova att flytta enheten till en annan position. 
        Undvik att lägga den bakom t ex skyddsplåtar eller andra metalliska föremål.
        Du kan också prova en annan vinkel på montaget
         (t ex stående istället för liggande). 
         Testa en ny position och på knappen enligt bild ovan. 
         Även om den blir orange kan ovan testas för att få grön markering
        </i>
      </div>

    </div>
  );
}

 

export function Verify(callback){
  return(
    <div>

      <div className="content-box">
        <div className="content-title">
           Nu är det dags att kontrollera och verifiera installation, enligt följande:
        </div>
       
      </div>

      <div className="content-box mt-3">
          <strong>1.</strong> SG-A ska vara inkopplad och i drift
           med <span className="color-green text-big">&#9473;</span> grön lysdiod.
     </div>

     <div className="content-box mt-3">        
           <p>
            <strong>2.</strong> Kontrollera (på värmepumpen) att nuvarande utomhustemperatur visas korrekt.           
          </p>
          <div className='ml-3'>
              <p className='bold'><u>Gör följande:</u></p>
              <p>
                - Tryck och håll inne den blå knappen i tre sekunder,
                  och utegivartemperatur simuleras på värmepump i tre olika steg 
                  med 15 sek intervall. 
                  Först visas +10 grader, sedan 0 grader och slutligen -10 grader.
              </p>
              
                Om värdena är helt felaktiga, byt plats på röd/grå 
                ledare och prova igen. Blinkar lysdiod orange, 
                så är ej switch på SG-A i läge ”On" 
          </div>         
     </div>

     <div className="content-box mt-3">
          <strong>3.</strong> Direkt efter test av utegivaretemperaturer ovan, 
           så kommer externa ingångar aktiveras på värmepumpen, 
           också med 15 sek intervall. 
           Kontrollera på värmepumpens display att den reagerar på denna signal.
      </div>

    </div>
  );
}


export function Support(props) {
  return(
    <div className="p-2 relative">
       <h5><i className="fa fa-headphones" aria-hidden="true"></i> Support</h5>
       <p>Behöver du hjälp med installationen<br/> kan du kontakta oss.</p>
       <p>
         Öppettider: 08.00 - 16.00 (vardagar)<br/>
         Tel: 035-710 11 50<br/>
         Mail: support@easyserv.se
       </p>
    </div>
  );
}

export function Footer(props) {
  return (
    <div className="w-50 mt-3 mb-4 mx-auto trans text-center">
      <img src="/img/logo-w.png" width="100%" className="w-100" />
    </div>
  )
}

export function DeviceGuide(docId, props){

  const CloseWarningModal = () => {
    props.setWarningModal(false);
    console.log("props.WarningModal", props.WarningModal);
  }

  const StepData = (section) => {
    var resp = '';
    switch(section) {
      case "dem" : resp = props.stateGuideDem; break;
      case "cable" : resp = props.stateGuideCable; break;
      case "inst" : resp = props.stateGuideInst; break;
      case "drift" : resp = props.stateGuideDrift; break;
      case "end" : resp = props.stateGuideEnd; break;
      case "first" : resp = props.stateGuideFirst; break;
      default: resp = ""; break;
    }
    return resp;
  }

  const NextStep = () => {
    const countUp = parseInt(props.stateStepGuide + 1);
    props.setStepGuide(countUp);
    props.ScrollToTop();
  }


  const BeforeStep = () => {
    const countUp = parseInt(props.stateStepGuide - 1);
    props.setStepGuide(countUp);
  }

  // Check if list have img
  const ViewImg = (id, img) =>{
    const imgUrl = '/guide/'+id+'/'+img;
    var resp = '';
    if(img){
      resp = '<img src='+imgUrl+' width="100%" className="mt-2 mx-auto w-50 w-max-100">';
    }
    return Functions.renderHTML(resp);
  }

  const StepList = (section, title, step) =>{
    //const listType = Functions.QueryValue('type');
    const viewWarningModal = props.WarningModal;
    const viewStep = props.stateStepGuide;
    const viewBefore = step - 1;
    const viewNext = step + 1;

    const itemClass = (section === 'first') ? 'relative p-3 mb-3  content-box':'relative p-3 mb-3 content-box';
    const itemTxt = 'Nästa steg';
    const data = StepData(section);
    //apidata.sort((a, b) => a.pos - b.pos);


    const stepPos = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P'];
    return(
      <div className="" id={"step-"+step}>
        {viewStep === 1 && viewWarningModal && 
          <div className="modal" style={{display:'block'}}>
                <div className="modal-dialog">          
                      <div className='content-box modal-content text-center' style={{       
                              marginTop:'5rem',
                              fontSize:'1em',
                              backgroundColor:'#FF0000', 
                              color:'#FFFFCC'}}>
                              <h3 className='bold'>
                                <i className='fa fa-exclamation-triangle'></i> <i className='fa fa-flash'></i> 
                                <br/>
                                OBS!
                              </h3>
                              <div className='no-wrap bold'>
                                Viktigt att slå av huvudströmbrytare <i className='fa fa-plug'></i>
                              </div>
                              för värmepump innan installationen påbörjas.<br/> 
                              (om huvudströmbrytare ej finns-slå ifrån säkringar)

                              <div className="text-center">
                                <div onClick={()=>CloseWarningModal()} className="pointer btn btn-border mt-3 mx-auto">Uppfattat</div>
                              </div>
                        </div>

               
                </div>
             </div>
        }

        {viewStep === step &&
          <div className="">           

            { data &&
              <>
                {
                  data.map((item, i) => (
                    <div className={itemClass} key={i}>
                      {i === 0 &&  
                        <>
                          {step === 0 ?
                                    (
                                    <div className="text-center mb-2 line">
                                        <h5 className="bold">
                                          {title}
                                        </h5>
                                    </div>
                                    ):(
                                      <div className="text-center mb-2 line">
                                      <h5 className="bold">
                                          {viewStep}. {title}
                                      </h5>
                                  </div>
                                    )
                            }
                          </>
                        }

                        <div className="bold d-flex">                       
                            {section === 'first' ?
                              (<>                       
                                <div className="text-center hidden"><h3>{item.title}</h3></div>
                              </>):(
                                <div className="text-2 bold">
                                  <div><font className="">{stepPos[i]}.</font> {item.title}</div>
                                </div>
                              )
                            }
                        </div>
                        <div className="">{Functions.renderHTML(item.desc)}</div>
                        <div className="">{ViewImg(docId, item.img)}</div>
                    </div>
                  ))
                }
              </>
            }

            <div className="d-flex mb-4">
              {Number(viewNext) === 4 ? (<>
                  {props.BtnNext('next')}
                </>):(
                <>
                  <div  onClick={()=>NextStep()} className="btn btn-primary bold btn-block p-3 mt-3 w-100  no-wrap ml-auto">{itemTxt} &nbsp;&nbsp;<i className="fa fa-angle-right"></i></div>
                </>
              )}
            </div>
          </div>
        }
      </div>
    );
  }


  return(
    <div>
      { docId ? (
        <div>
          {StepList('first', props.titleDeviceModel, 0)}
          {StepList('dem', 'Demontering för installation', 1)}
          {StepList('cable', 'Installation hårdvara', 2)}
          {StepList('inst', 'Återställning efter installation', 3)}
          {StepList('drift', 'Driftsättning', 4)}
        </div>
      ):(
        <div>
          <div className="text-center"><h4>Guide saknas</h4></div>

          <div className="mt-3 p-2 content-info text-center">
            <i>
              <i className="fa fa-info-circle text-2"></i>  Installationsguide saknas
              för värmepumpsmodellen <strong>{props.titleDeviceModel}</strong>.<br/>
              Kontakta oss om du behöver hjälp med installationen.
              <br />
            </i>
          </div>

          {props.BtnNext('next', 'Nästa steg')}

        </div>
      )
      }
    </div>
  )
}
