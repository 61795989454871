/*import { useRef } from "react";
import jwt_decode from "jwt-decode";
import { useAuth } from "sg-frontend-shared/src/hooks/useAuth";
import { useLocation, useNavigate } from 'react-router-dom';
*/
function Logout() {
  /*
  const { token, doLogin, doLogout, userPoolAuthURL,userPoolClientId } = useAuth();
  const loginDoneRef = useRef(false); // to only do login once
  const location = useLocation();
  const decoded = (token) ? jwt_decode(token):'';
  console.log("auth", location, decoded);


  if (!token) {
      //window.location.replace('/');
    //return (null);
  }

  const logout = () => {
    //window.location.replace('/');
    doLogout();

  }

  return (
    <div className="container">
      <p>{userPoolAuthURL}</p>
      <p>{userPoolClientId}</p>
      <button onClick={()=>logout()}>Logout</button>

    </div>
  );
  */

  return null;
}

export default Logout;

/*
https://easyserv-unstable.auth.eu-north-1.amazoncognito.com/login?response_type=token&client_id=ij27u36cksoq1bo06lmn8k13i&redirect_uri=https://localhost:3004/oauth/callback&scope=openid+email&state=7lOm0Fwiyg67NtcT0zTY72MT3Zlx7nJqdlnHIDVr

*/
