import { Navigate, useOutlet } from "react-router-dom";

import { useAuth } from "sg-frontend-shared/src/hooks/useAuth";

//import Logo from 'sg-frontend-shared/src/components/Logo';

function HomeLayout() {
  const { token } = useAuth();
  const outlet = useOutlet();

  if (token) {
    return <Navigate to="/start" replace />;
  }

/*
  return (
    <div>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link onClick={() => doLogin()}>Login</Link>
        </li>
      </ul>

      <hr />

      { outlet }
    </div>
  );*/

  return(
    <div>
      {/*<Logo/>*/}
      <div className="container">
        { outlet }
      </div>
    </div>
  );
};

export default HomeLayout;
