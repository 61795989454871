import { createContext, useContext, useMemo, useState, useCallback } from "react";

const redirectUri = `${window.location.origin}/oauth/callback`;

// store a random state value in session storage
// to check/verify against after callback
const stateKey = 'easyserv-auth-state';
const redirectKey = 'easyserv-redirect';

const generateState = () => {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let array = new Uint8Array(40);
	window.crypto.getRandomValues(array);
	array = array.map((x) => validChars.codePointAt(x % validChars.length));
	const randomState = String.fromCharCode.apply(null, array);
	return randomState;
};

const setState = (state) => {
	sessionStorage.setItem(stateKey, state);
};

const checkState = (receivedState) => {
	const state = sessionStorage.getItem(stateKey);
	return state === receivedState;
};

const removeState = () => {
	sessionStorage.removeItem(stateKey);
};

const setRedirect = (redirect) => {
	sessionStorage.setItem(redirectKey, redirect);
};

const getRedirect = () => {
	return sessionStorage.getItem(redirectKey);
};

const removeRedirect = () => {
	sessionStorage.removeItem(redirectKey);
};

const AuthContext = createContext();

export const AuthProvider = ({ children, userPoolAuthURL, userPoolClientId }) => {
  const [token, setToken] = useState(null); // jwt id_token, only stored in memory (never in session storage or local storage)
  const [error, setError] = useState(null);
  const [stateLogOut, setLogOut] = useState(false);

  const authURL = `${userPoolAuthURL}/oauth2/authorize?response_type=token&client_id=${userPoolClientId}&`;

  // redirect the user to Cognito
  const doLogin = useCallback((redirect) => {
    const state = generateState();
    setState(state);
    const url = `${userPoolAuthURL}/oauth2/authorize?response_type=token&client_id=${userPoolClientId}&redirect_uri=${redirectUri}&scope=openid+email&state=${state}`;
    if (redirect) {
      setRedirect(redirect);
    } else {
      removeRedirect();
    }

    // window.location.replace(url);
    window.location.href = url;
  }, []);

  // verify (and use) the data we get in the callback from Cognito
  const verifyLogin = useCallback((payload) => {
    const params = new URLSearchParams(payload);
    const error = params.get("error");
    const state = params.get("state");
    const idToken = params.get("id_token");
    let redirect = null;
    if (error) {
      setError(error);
      setToken(null);
    } else if (state && checkState(state)) {
      setToken(idToken);
      setError(null);
      redirect = getRedirect();
    }
    removeState();
    removeRedirect();
    return redirect;
  }, []);


  // log out //////////////////////////
  const doLogout = useCallback(() => {
    const state = generateState();
    setState(state);
    //const url = `${userPoolAuthURL}/logout?response_type=token&client_id=${userPoolClientId}&redirect_uri=${redirectUri}&scope=openid+email&state=${state}`;
    const url = `${userPoolAuthURL}/login?response_type=token&client_id=${userPoolClientId}&redirect_uri=${redirectUri}&scope=openid+email&state=${state}`;
    
    //empty
    localStorage.setItem("tenant", ""); 
    localStorage.setItem("tenantAccess", ''); 
    localStorage.setItem("userName", "");

    console.log("*** auth logout ****", url, state);
    window.location.href = url;

    setLogOut(true);
    //setToken(null);

  },[]);

  const value = useMemo(
    () => ({
      token,
      error,
      doLogin,
      doLogout,
      verifyLogin,
      userPoolAuthURL,
      userPoolClientId
    }),
    [token, error, doLogin, doLogout, verifyLogin,userPoolAuthURL,userPoolClientId],
  );


  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
